const paymentForm = document.querySelector("#payment-form form");
const modalConfirmBtn = document.querySelector("#paymentConfirmedBtn");
const paymentRequestFormContainer = document.querySelector(
  "#payment-request-form"
);

if (paymentForm) {
  paymentForm.addEventListener("submit", (e) => {
    e.preventDefault();

    url = paymentForm.getAttribute("action");
    fetch(url, {
      body: new FormData(e.target),
      method: "POST",
    })
      .then((response) => response.json())
      .then((json) => {
        paymentRequestFormContainer.innerHTML = json.content;
        let paymentRequestForm = document.querySelector(
          "#payment-request-form form"
        );
        paymentRequestForm.setAttribute(
          "enctype",
          "application/x-www-form-urlencoded"
        );
        paymentRequestForm.requestSubmit();
      });
  });

  modalConfirmBtn.addEventListener("click", function () {
    //.requestSubmit() simule le click du submit, et permet le preventDefault() contrairement à.submit()
    paymentForm.requestSubmit();
  });
}
